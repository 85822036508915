import React, { useState, useEffect } from 'react';
import { Card, Table, FormControl } from 'react-bootstrap';
import axios from 'axios'; // Make sure to install axios
import global_URL from '../../../Const/urls';
import { branch } from '../../../Const/variables';

const RecentTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // Fetch recent transactions from the API
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(`${global_URL}api/dashboardz/Recent-transactions/${branch}`);
        setTransactions(response.data);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchTransactions();
  }, []);

  // Handle search input change
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter transactions based on search query
  const filteredTransactions = transactions.filter(transaction =>
    transaction.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Card className="p-4">
      <h5>Recent Transactions</h5>
      <FormControl
        type="search"
        placeholder="Search user..."
        className="mb-3"
        value={searchQuery}
        onChange={handleSearch}
      />
      <Table responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Product</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {filteredTransactions.map((transaction, index) => (
            <tr key={index}>
              <td>{transaction.name}</td>
              <td>{transaction.productName}</td>
              {/* Convert string to number and format with commas */}
              <td>{parseInt(transaction.amount, 10).toLocaleString()}/=</td>
              <td>
                <span className={`badge ${transaction.status === 2 ? 'bg-success' : 'bg-warning'}`}>
                  {transaction.status === 0 ? "Pending" : "Confirmed"}
                </span>
              </td>
              <td>{transaction.date}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default RecentTransactions;
