import React, { useState, useEffect } from 'react';
import { Card, Table, Pagination, FormControl } from 'react-bootstrap';
import axios from 'axios';
import global_URL from '../../../Const/urls';
import { branch } from '../../../Const/variables';

const TopProducts = () => {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // Fetch top products on component mount
  useEffect(() => {
    const fetchTopProducts = async () => {
      try {
        const response = await axios.get(`${global_URL}api/dashboardz/Top-products/${branch}`);
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching top products:', error);
      }
    };

    fetchTopProducts();
  }, []);

  // Handle search
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter products by search query
  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Card className="p-4">
      <h5>Top Products by Units Sold</h5>
      <FormControl
        type="search"
        placeholder="Search products..."
        className="mb-3"
        value={searchQuery}
        onChange={handleSearch}
      />
      <Table responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Price</th>
            <th>Units Sold</th>
          </tr>
        </thead>
        <tbody>
          {filteredProducts.map((product, index) => (
            <tr key={index}>
              <td>{product.name}</td>
              <td>{parseInt(product.price, 10).toLocaleString()}/=</td>
              <td>{product.unitsSold}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* <Pagination className="justify-content-end">
        <Pagination.Prev>Previous</Pagination.Prev>
        <Pagination.Item active>1</Pagination.Item>
        <Pagination.Next>Next</Pagination.Next>
      </Pagination> */}
    </Card>
  );
};

export default TopProducts;
