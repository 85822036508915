
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';
import RecentTransactions from './recentOrders';
import TopProducts from './topProducts';
import BarChart from './barChart';
import LineChart from './lineChart';
import { branch, role } from '../../../Const/variables';
import { useEffect, useState } from 'react';
import axiosi from '../../../Services/axioz';
import global_URL from '../../../Const/urls';
import { submitDailyReport } from '../../../Controllers/SalesProfitLossController';
import  moment from 'moment'; 


// Dashboard Component
const Dashboard = () => {
  // State to hold total sales
  const [totalSales, setTotalSales] = useState(null);
  const [totalExpenses, setTotalExpenses] = useState(null);
  const [totalLosses, setTotalLosses] = useState(null);
  const [totalPurchased, setTotalPurchased] = useState(null);
  const [totalProfit, setTotalProfit] = useState(null);

  const [totalSalesManager, setTotalSalesManager] = useState(null);
  const [totalExpensesManager, setTotalExpensesManager] = useState(null);
  const [totalLossesManager, setTotalLossesManager] = useState(null);
  const [totalPurchasedManager, setTotalPurchasedManager] = useState(null);
  const [totalProfitManager, setTotalProfitManager] = useState(null);

  const [showConfirmModal, setShowConfirmModal] = useState(false); 

  const [isReportSubmitted, setIsReportSubmitted] = useState(false); // New state for report submission
  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));


  useEffect(() => {
    const lastSubmittedDate = localStorage.getItem('lastSubmittedDate');
    if (lastSubmittedDate === currentDate) {
      setIsReportSubmitted(true);  // Report has already been submitted today
    } else {
      setIsReportSubmitted(false); // Reset for a new day
    }
  }, [currentDate]);


   // After report is submitted, store the submission date in localStorage
   useEffect(() => {
    if (isReportSubmitted) {
      localStorage.setItem('lastSubmittedDate', currentDate);  // Store the current date when submitted
    }
  }, [isReportSubmitted, currentDate]);


  // Fetch total sales from the API on component mount
  useEffect(() => {
    const fetchTotalDataAdmin = async () => {
      //sales
      try {
        const response = await axiosi.get(`${global_URL}api/dashboards/getTotalSales`);
        setTotalSales(response.data.totalSales);  // Set total sales from API response
      } catch (error) {
        console.error('Error fetching total sales:', error);
        setTotalSales('!');
      }

      
// expenses 
      try {
        const response = await axiosi.get(`${global_URL}api/dashboards/getTotalExpenses`);
        setTotalExpenses(response.data.totalExpenses);  
      } catch (error) {
        console.error('Error fetching total expenses:', error);
        setTotalExpenses('!');
      }

        // expenses 
    try {
      const response = await axiosi.get(`${global_URL}api/dashboards/getTotalLosses`);
      setTotalLosses(response.data.totalLosses);  
    } catch (error) {
      console.error('Error fetching total losses:', error);
      setTotalLosses('!');
    }


    // Profit 

    try {
      const response = await axiosi.get(`${global_URL}api/dashboards/getTotalProfit`);
      setTotalPurchased(response.data.totalProfit);  

  // Initialize the variables, defaulting to an empty string if they are null or undefined
const totalSalesNum = parseFloat((totalSales || '').replace(/,/g, '')) || 0;
const totalExpensesNum = parseFloat((totalExpenses || '').replace(/,/g, '')) || 0;
const totalLossesNum = parseFloat((totalLosses || '').replace(/,/g, '')) || 0;
const totalPurchasedNum = parseFloat((totalPurchased || '').replace(/,/g, '')) || 0;

// 

// Calculate profit without the need for redundant || 0 checks
const profit = totalSalesNum - (totalLossesNum + totalExpensesNum + totalPurchasedNum);

// Format the profit with 2 decimal places and set the state
setTotalProfit(profit.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));


    } catch (error) {
      console.error('Error fetching total profit:', error);
      setTotalPurchased('!');
    }
    };

fetchTotalDataAdmin();
  },  [totalSales, totalExpenses, totalLosses, totalPurchased]);


  // manager UseEffect 

  useEffect(()=>{
    // for manager
    
    const fetchTotalDataManager = async () => {
      //sales
      try {
        const response = await axiosi.get(`${global_URL}api/dashboards/getTotalSaleswithBranch/${branch}`);
        setTotalSalesManager(response.data.totalSales);  // Set total sales from API response
      } catch (error) {
        console.error('Error fetching total sales:', error);
        setTotalSalesManager('!');
      }
    
      
     // expenses 
      try {
        const response = await axiosi.get(`${global_URL}api/dashboards/getTotalExpenseswithbranch/${branch}`);
        setTotalExpensesManager(response.data.totalExpenses);  
      } catch (error) {
        console.error('Error fetching total expenses:', error);
        setTotalExpensesManager('!');
      }
      // expenses 
    try {
      const response = await axiosi.get(`${global_URL}api/dashboards/getTotalLosseswithBranch/${branch}`);
      setTotalLossesManager(response.data.totalLosses);  
    } catch (error) {
      console.error('Error fetching total losses:', error);
      setTotalLossesManager('!');
    }
    // Profit 
    try {
      const response = await axiosi.get(`${global_URL}api/dashboards/getTotalProfitwithBranch/${branch}`);
      setTotalPurchasedManager(response.data.totalProfit);  
    
    // Initialize the variables, defaulting to an empty string if they are null or undefined
    const totalSalesNum = parseFloat((totalSalesManager || '').replace(/,/g, '')) || 0;
    const totalExpensesNum = parseFloat((totalExpensesManager || '').replace(/,/g, '')) || 0;
    const totalLossesNum = parseFloat((totalLossesManager || '').replace(/,/g, '')) || 0;
    const totalPurchasedNum = parseFloat((totalPurchasedManager || '').replace(/,/g, '')) || 0;
    
    // 
    
    // Calculate profit without the need for redundant || 0 checks
    const profit = totalSalesNum - (totalLossesNum + totalExpensesNum + totalPurchasedNum);
    
    // Format the profit with 2 decimal places and set the state
    setTotalProfitManager(profit.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
    
    
    } catch (error) {
      console.error('Error fetching total profit:', error);
      setTotalPurchasedManager('!');
    }
    };
    
    
    fetchTotalDataManager();
      },[totalSalesManager, totalExpensesManager, totalLossesManager, totalPurchasedManager]);

  // Ensure charts are destroyed before unmounting
  const cardDataAdmin= [
    {
      amount: totalSales !== null ? `${totalSales} TZS` : "0 TZS",  // Display fetched sales or loading state
      description: "Daily Sales",
      percentage: "22.45%",
      trend: "success",  // For upward trend
    },
    {
      amount: totalExpenses !== null? `${totalExpenses} TZS` : "0 TZS",
      description: "Expenses",
      percentage: "22.45%",
      trend: "danger",
    },
    {
      amount: totalLosses !== null? `${totalLosses} TZS` : "0 TZS",
      description: "Losses",
      percentage: "2.45%",
      trend: "danger", // For downward trend
    },
    {
      amount: totalProfit !== null? `${totalProfit} TZS` : "0 TZS",
      description:totalProfit<0?"Daily Loss": "Daily Profit",
      percentage: "0.45%",
      trend: "success",
    },
  ];

  const cardDataManager= [
    {
      amount: totalSalesManager !== null ? `${totalSalesManager} TZS` : "0 TZS",  // Display fetched sales or loading state
      description: "Daily Sales",
      percentage: "22.45%",
      trend: "success",  // For upward trend
    },
    {
      amount: totalExpensesManager !== null? `${totalExpensesManager} TZS` : "0 TZS",
      description: "Expenses",
      percentage: "22.45%",
      trend: "danger",
    },
    {
      amount: totalLossesManager !== null? `${totalLossesManager} TZS` : "0 TZS",
      description: "Losses",
      percentage: "2.45%",
      trend: "danger", // For downward trend
    },
    {
      amount: totalProfitManager !== null? `${totalProfitManager} TZS` : "0 TZS",
      description: "Daily Profit",
      percentage: "0.45%",
      trend: "success",
    },
  ];


  const handleConfirmClick = () => {
    setShowConfirmModal(true);
  };


  return (
    <div>
      <Container fluid className="p-4">
        {/* Top Summary Cards */}
        <Row className="mb-4">
          {(role === "admin" ? cardDataAdmin : cardDataManager).map((card, index) => (
            <Col md={3} key={index}>
              <Card className="p-3">
                <Card.Body>
                  <h5 className="mb-2">{card.amount}</h5>
                  <p className="text-muted">{card.description}</p>
                  <small className={`text-${card.trend}`}>{card.percentage} {card.trend === "success" ? "↑" : "↓"}</small>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
  
        {role === 'admin' && (
          <Row className="mb-4">
            <Col md={6}>
              <Card className="p-4">
                <Card.Body>
                  <h5>Weekly/Monthly Profit</h5>
                  <LineChart />
                </Card.Body>
              </Card>
            </Col>
  
            {/* Last 7 Days Sales */}
            <Col md={6}>
              <Card className="p-4">
                <Card.Body>
                  <h5>Last 7 Days Sales</h5>
                  {/* <h6>1,259 Items Sold</h6>
                  <h6>3,000,000 Revenue</h6> */}
                  <BarChart />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
  
        {(role === 'manager' || role === 'staff') && (
          <Row className="mb-4">
            <Col md={6}>
              <RecentTransactions />
            </Col>
            <Col md={6}>
              <TopProducts />
            </Col>
          </Row>
        )}
      </Container>
  
      {/* Button adopting theme colors */}
      {role==="admin" ?(
        null
      ):(
        <button
        className={`btn ${isReportSubmitted ? 'btn-success' : 'btn-secondary'} position-fixed p-3`}
        style={{ bottom: '20px', right: '20px', zIndex: 1000 }}
        onClick={isReportSubmitted ? null : handleConfirmClick} // Disable if report is submitted
      >
        {isReportSubmitted ? "Today's Report is Submitted" : 'Submit Daily Report'}
      </button>

      )}
     
     <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to submit report?</p>
          <p className='text-secondary' >*Because once you have submitted you can't re-submit/re-do!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancel
          </Button>
          <Button 
  variant="primary"
  onClick={() => {
    // Safely handle null or undefined values by using an empty string if any variable is null or undefined
    const sales = (totalSalesManager || '0').replace(/,/g, ''); 
    const expenses = (totalExpensesManager || '0').replace(/,/g, ''); 
    const losses = (totalLossesManager || '0').replace(/,/g, ''); 
    const purchased = (totalPurchasedManager || '0').replace(/,/g, '');
    const profit = (totalProfitManager || '0').replace(/,/g, '');

    submitDailyReport(
      parseInt(sales, 10), 
      parseInt(expenses, 10), 
      parseInt(losses, 10), 
      parseInt(purchased, 10),
      parseInt(profit, 10)
    );

    setShowConfirmModal(false);
    setIsReportSubmitted(true);
  }}
>
  Confirm
</Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default Dashboard;
