import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import { Accordion, Dropdown } from 'react-bootstrap';
import { FaUser, FaPlus, FaUsers, FaChevronDown, FaChevronUp, FaBox, FaTachometerAlt, FaShoppingCart } from 'react-icons/fa';
import logo from '../../Assets/LOGO-PNG-01.png';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../Services/Auth/authService';
import CreateStaff from './staffs/addStaff';
import GetStaff from './staffs/getStaff';
import CreateUOM from './uom/addUOM';
import GetCategory from './categories/getcategories';
import CreateCategory from './categories/addcategories';
import GetUOM from './uom/getUOM';
import CreateProduct from './products/addproducts';
import GetProduct from './products/getProducts';
import CreateSale from '../Staff/Sales/addSales';
import GetSales from '../Staff/Sales/getSales';
import GetInventories from './inventories/getinventories';
import Dashboard from '../components/dashboard/dashboard_Index'
import {branch, role, username} from '../../Const/variables';
import GetOrderz from './orders/getOrders';
import CreateLoss from './losses/addLoss';
import GetLosses from './losses/getLosses';
import CreateExpense from './expenses/addExpense';
import { goToProfilePage } from '../../Shared/Functions/navigate';

const ManagerSidebar = () => {
  const [isCollapsed] = useState(false);
  const [isStaffsOpen, setIsStaffsOpen] = useState(false);
  const [isUOMsOpen, setIsUOMsOpen] = useState(false);
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isSalesOpen, setIsSalesOpen] = useState(false);
  const [isExpensesOpen, setIsExpensesOpen] = useState(false);
  const [activeComponent, setActiveComponent] = useState('');
 
  const [activeLink, setActiveLink] = useState('dashboard'); // Default active link

//   const username = JSON.parse(localStorage.getItem('username'));
//   const role = JSON.parse(localStorage.getItem('role'));
  const navigate = useNavigate();

 

  const navigateTo = (path) => {
    navigate(path);
  };

  // const toggleSidebar = () => {
  //   setIsCollapsed(!isCollapsed);
  // };

  const toggleStaffsSection = () => {
    setIsStaffsOpen(!isStaffsOpen);
  };
  const toggleUOMsSection = () => {
    setIsUOMsOpen(!isUOMsOpen);
  };
  const toggleCategoriesSection = () => {
    setIsCategoriesOpen(!isCategoriesOpen);
  };
  const toggleProductsSection = () => {
    setIsProductsOpen(!isProductsOpen);
  };
  const toggleSalesSection = () => {
    setIsSalesOpen(!isSalesOpen);
  };

  const toggleExpensesSection = () => {
    setIsExpensesOpen(!isExpensesOpen);
  };

  
  const handleComponentChange = (componentName, linkName) => {
    setActiveComponent(componentName);
    setActiveLink(linkName); // Set the active link
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'dashboard':
        return <Dashboard />;
      case 'all-staffs':
        return <GetStaff />;
      case 'create-staffs':
        return <CreateStaff />;
      case 'all-uoms':
        return <GetUOM />;
      case 'create-uoms':
        return <CreateUOM />;
      case 'all-categories':
        return <GetCategory />;
      case 'create-categories':
        return <CreateCategory />;
      case 'all-products':
        return <GetProduct />;
      case 'create-products':
        return <CreateProduct />;
        case 'all-sales':
        return <GetSales />;
      case 'create-sales':
        return <CreateSale />;
        case 'all-expenses':
        return <GetLosses />;
        case 'create-loss':
        return <CreateLoss />;
        case 'create-expense':
        return <CreateExpense />;
      case 'inventories':
        return <GetInventories />;
        case 'orders':
        return <GetOrderz />;
      default:
        return <Dashboard/>;
    }
  };

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <div
        className={`bg-primary text-white vh-100 d-flex flex-column p-2 ${isCollapsed ? 'collapsed' : ''}`}
        style={{ width: isCollapsed ? '110px' : '250px', transition: 'width 0.3s', position: 'relative' }}
      >
        <img
          src={logo}
          alt="Dorcas Company LTD"
          className="img-fluid p-3"
          style={{ maxHeight: '100px', maxWidth: '100px', objectFit: 'contain' }}
        />
        {!isCollapsed && (
          <div className="d-flex justify-content-center mb-3">
            <h5 style={{ fontWeight: 'bold' }}>DORCAS COMPANY LTD</h5>
          </div>
        )}
        <Nav defaultActiveKey="/dashboard" className="flex-column flex-grow-1">
          <Nav.Link
            className={`d-flex ${isCollapsed ? 'flex-column align-items-center justify-content-center' : 'align-items-center justify-content-start'} ${activeLink === 'dashboard' ? 'text-primary bg-white rounded' : 'text-white'} `}
            onClick={() => handleComponentChange('dashboard', 'dashboard')}
          >
            <FaTachometerAlt size={isCollapsed ? '24px' : '20px'} />
            {!isCollapsed && <span style={{ fontWeight: 'bold' }} className="ms-2">Dashboard</span>}
            {isCollapsed && <span style={{ fontWeight: 'bold' }} className="mt-1">Dashboard</span>}
          </Nav.Link>

          {/* Staffs Section */}
          <div className="d-flex flex-column">
            <Nav.Link
              className={`text-white d-flex ${isCollapsed ? 'flex-column align-items-center justify-content-center' : 'align-items-center justify-content-start'} ${activeLink === 'staffs' ? 'text-primary bg-white rounded' : 'text-white'}`}
              onClick={toggleStaffsSection}
              aria-controls="staff-collapse"
              aria-expanded={isStaffsOpen}
              style={{ position: 'relative' }}
            >
              <div className={`d-flex ${isCollapsed ? 'flex-column align-items-center' : 'align-items-center justify-content-between'}`} style={{ width: '100%' }}>
                <div className="d-flex align-items-center">
                  <FaUsers size={isCollapsed ? '24px' : '20px'} />
                  {!isCollapsed && <span style={{ fontWeight: 'bold' }} className="ms-2">Staffs</span>}
                  {isCollapsed && <span style={{ fontWeight: 'bold' }} className="mt-1">Staffs</span>}
                </div>
                <span className="d-flex align-items-center">
                  {isStaffsOpen ? <FaChevronUp size="16px" /> : <FaChevronDown size="16px" />}
                </span>
              </div>
            </Nav.Link>
            <Accordion activeKey={isStaffsOpen ? '0' : null}>
              <Accordion.Collapse eventKey="0">
                <div id="staff-collapse" className={`ms-${isCollapsed ? '3' : '4'}`}>
                  <Nav.Link onClick={() => handleComponentChange('all-staffs', 'all-staffs')} className={`${activeLink === 'all-staffs' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaUser size="16px" className="me-2" />
                    {!isCollapsed && <span>All Staffs</span>}
                  </Nav.Link>
                  <Nav.Link onClick={() => handleComponentChange('create-staffs', 'create-staffs')} className={`${activeLink === 'create-staffs' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaPlus size="16px" className="me-2" />
                    {!isCollapsed && <span>Create Staff</span>}
                  </Nav.Link>
                </div>
              </Accordion.Collapse>
            </Accordion>
          </div>

          {/* Unit of Measurements Section */}
          <div className="d-flex flex-column">
            <Nav.Link
              className={`text-white d-flex ${isCollapsed ? 'flex-column align-items-center justify-content-center' : 'align-items-center justify-content-start'} ${activeLink === 'uoms' ? 'text-primary bg-white rounded' : 'text-white'}`}
              onClick={toggleUOMsSection}
              aria-controls="uom-collapse"
              aria-expanded={isUOMsOpen}
              style={{ position: 'relative' }}
            >
              <div className={`d-flex ${isCollapsed ? 'flex-column align-items-center' : 'align-items-center justify-content-between'}`} style={{ width: '100%' }}>
                <div className="d-flex align-items-center">
                  <FaUsers size={isCollapsed ? '24px' : '20px'} />
                  {!isCollapsed && <span style={{ fontWeight: 'bold' }} className="ms-2">UOM</span>}
                  {isCollapsed && <span style={{ fontWeight: 'bold' }} className="mt-1">UOM</span>}
                </div>
                <span className="d-flex align-items-center">
                  {isUOMsOpen ? <FaChevronUp size="16px" /> : <FaChevronDown size="16px" />}
                </span>
              </div>
            </Nav.Link>
            <Accordion activeKey={isUOMsOpen ? '0' : null}>
              <Accordion.Collapse eventKey="0">
                <div id="uom-collapse" className={`ms-${isCollapsed ? '3' : '4'}`}>
                  <Nav.Link onClick={() => handleComponentChange('all-uoms', 'all-uoms')} className={`${activeLink === 'all-uoms' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaUser size="16px" className="me-2" />
                    {!isCollapsed && <span>All UOM</span>}
                  </Nav.Link>
                  <Nav.Link onClick={() => handleComponentChange('create-uoms', 'create-uoms')} className={`${activeLink === 'create-uoms' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaPlus size="16px" className="me-2" />
                    {!isCollapsed && <span>Create UOM</span>}
                  </Nav.Link>
                </div>
              </Accordion.Collapse>
            </Accordion>
          </div>

          {/* Categories Section */}
          <div className="d-flex flex-column">
            <Nav.Link
              className={`text-white d-flex ${isCollapsed ? 'flex-column align-items-center justify-content-center' : 'align-items-center justify-content-start'} ${activeLink === 'categories' ? 'text-primary bg-white rounded' : 'text-white'}`}
              onClick={toggleCategoriesSection}
              aria-controls="categories-collapse"
              aria-expanded={isCategoriesOpen}
              style={{ position: 'relative' }}
            >
              <div className={`d-flex ${isCollapsed ? 'flex-column align-items-center' : 'align-items-center justify-content-between'}`} style={{ width: '100%' }}>
                <div className="d-flex align-items-center">
                  <FaUsers size={isCollapsed ? '24px' : '20px'} />
                  {!isCollapsed && <span style={{ fontWeight: 'bold' }} className="ms-2">Categories</span>}
                  {isCollapsed && <span style={{ fontWeight: 'bold' }} className="mt-1">Categories</span>}
                </div>
                <span className="d-flex align-items-center">
                  {isCategoriesOpen ? <FaChevronUp size="16px" /> : <FaChevronDown size="16px" />}
                </span>
              </div>
            </Nav.Link>
            <Accordion activeKey={isCategoriesOpen ? '0' : null}>
              <Accordion.Collapse eventKey="0">
                <div id="categories-collapse" className={`ms-${isCollapsed ? '3' : '4'}`}>
                  <Nav.Link onClick={() => handleComponentChange('all-categories', 'all-categories')} className={`${activeLink === 'all-categories' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaUser size="16px" className="me-2" />
                    {!isCollapsed && <span>All Categories</span>}
                  </Nav.Link>
                  <Nav.Link onClick={() => handleComponentChange('create-categories', 'create-categories')} className={`${activeLink === 'create-categories' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaPlus size="16px" className="me-2" />
                    {!isCollapsed && <span>Create Category</span>}
                  </Nav.Link>
                </div>
              </Accordion.Collapse>
            </Accordion>
          </div>

          {/* Products Section */}
          <div className="d-flex flex-column">
            <Nav.Link
              className={`text-white d-flex ${isCollapsed ? 'flex-column align-items-center justify-content-center' : 'align-items-center justify-content-start'} ${activeLink === 'products' ? 'text-primary bg-white rounded' : 'text-white'}`}
              onClick={toggleProductsSection}
              aria-controls="products-collapse"
              aria-expanded={isProductsOpen}
              style={{ position: 'relative' }}
            >
              <div className={`d-flex ${isCollapsed ? 'flex-column align-items-center' : 'align-items-center justify-content-between'}`} style={{ width: '100%' }}>
                <div className="d-flex align-items-center">
                  <FaUsers size={isCollapsed ? '24px' : '20px'} />
                  {!isCollapsed && <span style={{ fontWeight: 'bold' }} className="ms-2">Products</span>}
                  {isCollapsed && <span style={{ fontWeight: 'bold' }} className="mt-1">Products</span>}
                </div>
                <span className="d-flex align-items-center">
                  {isProductsOpen ? <FaChevronUp size="16px" /> : <FaChevronDown size="16px" />}
                </span>
              </div>
            </Nav.Link>
            <Accordion activeKey={isProductsOpen ? '0' : null}>
              <Accordion.Collapse eventKey="0">
                <div id="products-collapse" className={`ms-${isCollapsed ? '3' : '4'}`}>
                  <Nav.Link onClick={() => handleComponentChange('all-products', 'all-products')} className={`${activeLink === 'all-products' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaUser size="16px" className="me-2" />
                    {!isCollapsed && <span>All Products</span>}
                  </Nav.Link>
                  <Nav.Link onClick={() => handleComponentChange('create-products', 'create-products')} className={`${activeLink === 'create-products' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaPlus size="16px" className="me-2" />
                    {!isCollapsed && <span>Create Product</span>}
                  </Nav.Link>
                </div>
              </Accordion.Collapse>
            </Accordion>
          </div> 


                    {/* Sales Section */}
                    <div className="d-flex flex-column">
            <Nav.Link
              className={`text-white d-flex ${isCollapsed ? 'flex-column align-items-center justify-content-center' : 'align-items-center justify-content-start'} ${activeLink === 'sales' ? 'text-primary bg-white rounded' : 'text-white'}`}
              onClick={toggleSalesSection}
              aria-controls="products-collapse"
              aria-expanded={isSalesOpen}
              style={{ position: 'relative' }}
            >
              <div className={`d-flex ${isCollapsed ? 'flex-column align-items-center' : 'align-items-center justify-content-between'}`} style={{ width: '100%' }}>
                <div className="d-flex align-items-center">
                  <FaUsers size={isCollapsed ? '24px' : '20px'} />
                  {!isCollapsed && <span style={{ fontWeight: 'bold' }} className="ms-2">Sales</span>}
                  {isCollapsed && <span style={{ fontWeight: 'bold' }} className="mt-1">Sales</span>}
                </div>
                <span className="d-flex align-items-center">
                  {isSalesOpen ? <FaChevronUp size="16px" /> : <FaChevronDown size="16px" />}
                </span>
              </div>
            </Nav.Link>
            <Accordion activeKey={isSalesOpen ? '0' : null}>
              <Accordion.Collapse eventKey="0">
                <div id="sales-collapse" className={`ms-${isCollapsed ? '3' : '4'}`}>
                  <Nav.Link onClick={() => handleComponentChange('all-sales', 'all-sales')} className={`${activeLink === 'all-sales' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaUser size="16px" className="me-2" />
                    {!isCollapsed && <span>All Sales</span>}
                  </Nav.Link>
                  <Nav.Link onClick={() => handleComponentChange('create-sales', 'create-sales')} className={`${activeLink === 'create-sales' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaPlus size="16px" className="me-2" />
                    {!isCollapsed && <span>Preform Sale</span>}
                  </Nav.Link>
                </div>
              </Accordion.Collapse>
            </Accordion>
          </div> 

             {/* Expenses Section */}
             <div className="d-flex flex-column">
            <Nav.Link
              className={`text-white d-flex ${isCollapsed ? 'flex-column align-items-center justify-content-center' : 'align-items-center justify-content-start'} ${activeLink === 'expenses' ? 'text-primary bg-white rounded' : 'text-white'}`}
              onClick={toggleExpensesSection}
              aria-controls="expense-collapse"
              aria-expanded={isExpensesOpen}
              style={{ position: 'relative' }}
            >
              <div className={`d-flex ${isCollapsed ? 'flex-column align-items-center' : 'align-items-center justify-content-between'}`} style={{ width: '100%' }}>
                <div className="d-flex align-items-center">
                  <FaUsers size={isCollapsed ? '24px' : '20px'} />
                  {!isCollapsed && <span style={{ fontWeight: 'bold' }} className="ms-2">Expenses</span>}
                  {isCollapsed && <span style={{ fontWeight: 'bold' }} className="mt-1">Expenses</span>}
                </div>
                <span className="d-flex align-items-center">
                  {isSalesOpen ? <FaChevronUp size="16px" /> : <FaChevronDown size="16px" />}
                </span>
              </div>
            </Nav.Link>
            <Accordion activeKey={isExpensesOpen ? '0' : null}>
              <Accordion.Collapse eventKey="0">
                <div id="expenses-collapse" className={`ms-${isCollapsed ? '3' : '4'}`}>
                <Nav.Link onClick={() => handleComponentChange('all-expenses', 'all-expenses')} className={`${activeLink === 'all-expenses' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaUser size="16px" className="me-2" />
                    {!isCollapsed && <span>All Expenses</span>}
                  </Nav.Link>
                  <Nav.Link onClick={() => handleComponentChange('create-expense', 'create-expense')} className={`${activeLink === 'create-expense' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaPlus size="16px" className="me-2" />
                    {!isCollapsed && <span>Add Expense</span>}
                  </Nav.Link>
                  <Nav.Link onClick={() => handleComponentChange('create-loss', 'create-loss')} className={`${activeLink === 'create-loss' ? 'text-primary bg-white rounded' : 'text-white'}`}>
                    <FaPlus size="16px" className="me-2" />
                    {!isCollapsed && <span>Add Loss</span>}
                  </Nav.Link>
                </div>
              </Accordion.Collapse>
            </Accordion>
          </div> 

{/* inventory link  */}
          <Nav.Link 
            className={`d-flex ${isCollapsed ? 'flex-column align-items-center justify-content-center' : 'align-items-center justify-content-start'} ${activeLink === 'inventories' ? 'text-primary bg-white rounded' : 'text-white'}`}
            onClick={() => handleComponentChange('inventories', 'inventories')}
          >
            <FaBox size={isCollapsed ? '24px' : '20px'} />
            {!isCollapsed && <span style={{ fontWeight: 'bold' }} className="ms-2">Inventories</span>}
            {isCollapsed && <span style={{ fontWeight: 'bold' }} className="mt-1">Inventories</span>}
          </Nav.Link>

          {/* orders link  */}
          <Nav.Link 
            className={`d-flex ${isCollapsed ? 'flex-column align-items-center justify-content-center' : 'align-items-center justify-content-start'} ${activeLink === 'orders' ? 'text-primary bg-white rounded' : 'text-white'}`}
            onClick={() => handleComponentChange('orders', 'orders')}
          >
            <FaShoppingCart size={isCollapsed ? '24px' : '20px'} />
            {!isCollapsed && <span style={{ fontWeight: 'bold' }} className="ms-2">Orders</span>}
            {isCollapsed && <span style={{ fontWeight: 'bold' }} className="mt-1">Orders</span>}
          </Nav.Link>

        </Nav>

       
  

        <Dropdown.Divider />

        {/* Toggle button positioned at the bottom */}
        {/* <Button 
          onClick={toggleSidebar} 
          className="mt-auto mb-3" 
          style={{ width: '40px', height: '40px', alignSelf: 'center' }}
        >
          {isCollapsed ? <FaBars /> : <FaTimes />}
        </Button> */}

        <Dropdown>
        <div className="d-flex justify-content-center align-items-center">
  <Dropdown.Toggle
    variant="link"
    id="dropdown-basic"
    className="text-decoration-none text-center"
    style={{ color: 'white' }}
  >
    Ndugu: {username} : {role}

   
  </Dropdown.Toggle>
  
</div>

          <Dropdown.Menu align="end">
            <Dropdown.Item onClick={goToProfilePage}>Profile</Dropdown.Item>
            <Dropdown.Item onClick={() => navigateTo('/change-password')}>Change Password</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => {
              logout();
              navigateTo('/login');
            }}>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div className="d-flex justify-content-center align-items-center">
   {branch} Branch
  </div>

        {/* <Button
          className="mt-auto btn btn-light mx-3 mb-3"
          onClick={() => {
            logout();
            navigate('/');
          }}
        >
          <FaTimes className="me-2" />
          {!isCollapsed && 'Logout'}
        </Button>
        <Button
          className="mt-auto btn btn-light mx-3 mb-3"
          onClick={toggleSidebar}
        >
          {isCollapsed ? <FaBars /> : <FaTimes />}
        </Button> */}
      </div>

      <div className="flex-grow-1">
        {renderComponent()}
      </div>
    </div>
  );
};

export default ManagerSidebar;
