import jsPDF from 'jspdf';

export const generateReceipt = (cName,pname,pquantity,cost,receiptno,soldBy,totalAmount) => {
    const doc = new jsPDF();

    // Set up receipt details
    const receiptDetails = {
      receiptNumber: receiptno,
      customerName:cName,
      date: new Date().toLocaleDateString(),
      soldBy: soldBy,
      items: [
        { description: pname, price: pquantity*cost },
        // { description: 'Product 2', price: 50 },
        // { description: 'Product 3', price: 20 },
      ],
      totalAmount: totalAmount,
    };

    // Add Title
    doc.setFontSize(18);
    doc.text('Dorcas Company Limited', 90, 10);
    doc.text('Order', 90, 20);

    // Add receipt number and date
    doc.setFontSize(12);
    doc.text(`Receipt No: ${receiptDetails.receiptNumber}`, 10, 30);
    doc.text(`Customer Name: ${receiptDetails.customerName}`, 10, 40);
    doc.text(`Date: ${receiptDetails.date}`, 10, 50);
    doc.text(`Seller: ${receiptDetails.soldBy}`, 10, 60);

    doc.text('Particulars', 50, 70);
    // Add item list
    let yPosition = 80;
    receiptDetails.items.forEach((item, index) => {
      doc.text(`${index + 1}. ${item.description} - TZS ${item.price}`, 10, yPosition);
      yPosition += 10;
    });

    // Add total amount
    doc.setFontSize(14);
    doc.text(`Total: TZS ${receiptDetails.totalAmount}`, 10, yPosition + 10);

    // Download the PDF
    doc.save(`Receipt_${receiptDetails.receiptNumber} - ${receiptDetails.customerName}.pdf`);
  };